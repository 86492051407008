import { ContainerFluid, Icon, LinkAnchor, Logo, Section } from '@/atoms';
import { LinkButton } from '@/bloks/Button';
import { buildLinkProps } from '@/bloks/Button/LinkButton';
import DynamicComponent from '@/bloks/DynamicComponent';
import { SearchField } from '@/bloks/Page/SearchPage/SearchField';
import { SearchToggleButton } from '@/bloks/Page/SearchPage/SearchToggleButton';
import { SearchWidget } from '@/bloks/Page/SearchPage/SearchWidget';
import { GlobalMenuV2Storyblok, SearchPageStoryblok } from '@/components';
import { useServerPageConfig } from '@/contexts/config';
import { useGlobalMenu } from '@/contexts/menu/GlobalMenuProvider';
import { AddSearchProvider, useAddSearchProvider } from '@/contexts/search';
import { ISbStoryData } from '@/types/storyblok';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { t } from 'ttag';
import styles from './Header.module.scss';

/**
 * For displaying currently deployed version below the Fortnox logo.
 * Only enabled for internal deployment.
 */
const showInternalVersionTag = !!process.env.NEXT_PUBLIC_PREVIEW_ENABLED;

interface Props {
	blok: GlobalMenuV2Storyblok;
	searchPageStory?: ISbStoryData<SearchPageStoryblok>;
	enableLogin?: boolean;
	withInfoBanner?: boolean;
}

export const GlobalHeader: React.FC<Props> = ({
	blok,
	searchPageStory,
	enableLogin = true,
	withInfoBanner = false,
}) => {
	const search = useAddSearchProvider();

	const router = useRouter();

	const { showFirstPanel, setShowFirstPanel } = useGlobalMenu();

	return (
		<AddSearchProvider value={search}>
			<ContainerFluid
				theme="menu"
				className={cls(styles.container, withInfoBanner && styles.withInfoBanner, {
					[styles.searchOpen]: search.open,
				})}
				marginBottom="none"
			>
				<RemoveScroll enabled={showFirstPanel}>
					<DynamicComponent blok={blok} meta={{ enableLogin }} />
				</RemoveScroll>

				<Section
					as="header"
					lang="sv"
					{...editableBlok(blok)}
					className={cls(styles.header, {
						[styles.widgetOpen]: search.open,
					})}
					padding={{ base: 'md', lg: 'lg' }}
				>
					<nav className={styles.navLeft}>
						<button
							aria-label={t`Meny`}
							type="button"
							className={styles.menuButton}
							onClick={() => setShowFirstPanel(!showFirstPanel)}
							tabIndex={0}
						>
							<Icon name="bars" size="2x" color="--text-light" className={styles.icon} testID="hamburgarmeny" />
						</button>
						<div className={styles.logoWrapper}>
							<LinkAnchor href="/" className={styles.logo} aria-label={t`Länk till startsidan`}>
								<Logo size="138x30.14" />
							</LinkAnchor>
							{showInternalVersionTag && <InternalVersionTag />}
						</div>
						<div className={styles.linkWrapper}>
							{blok?.header?.map((item, index) => (
								<LinkAnchor
									key={item._uid}
									{...buildLinkProps(item.link)}
									hoverEffect
									className={cls(styles.navAnchor, item.link.url === router.asPath && styles.active)}
									data-testid={`menu-item-${index}`}
								>
									{item.title}
								</LinkAnchor>
							))}
						</div>
					</nav>

					{enableLogin && (
						<nav className={styles.navRight}>
							{search.ready && <SearchField search={search} />}
							<SearchToggleButton search={search} className={styles.searchToggleButton} />

							<LinkAnchor href="/kontakt" hoverEffect className={styles.navAnchorContact}>
								{t`Kontakt`}
							</LinkAnchor>

							<LinkButton href="https://apps.fortnox.se/fs/fs/login.php" variant="filled" className={styles.loginBtn}>
								{t`Logga in`}
							</LinkButton>

							<LinkAnchor
								href="https://apps.fortnox.se/fs/fs/login.php"
								className={styles.loginBtnMobile}
								hoverEffect
								data-testid={`login-button`}
							>
								{t`Logga in`}
							</LinkAnchor>
						</nav>
					)}
				</Section>
			</ContainerFluid>
			{search.ready && <SearchWidget search={search} blok={searchPageStory?.content} />}
		</AddSearchProvider>
	);
};

function InternalVersionTag() {
	const [hidden, setHidden] = useState(false);
	const config = useServerPageConfig();
	const router = useRouter();

	const handleClick: React.MouseEventHandler<HTMLInputElement> = (event) => {
		event.currentTarget.select();
	};

	const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
		if (event.key === 'Backspace') setHidden(true);
	};

	if (hidden) {
		return null;
	}

	const togglePreviewUrl = `${config.preview ? '/api/preview/exit-preview' : '/api/preview'}${router.asPath}`;
	const togglePreviewImpossible = !!router.query._storyblok; // Inside Storyblok
	const togglePreviewTitle = config.preview
		? '🚧 Stäng förhandsgranskning av ej publicerat innehåll'
		: '🚧 Starta förhandsgranskning av ej publicerat innehåll';

	return (
		<div className={styles.internalVersionTag}>
			<button
				onClick={() => window.location.assign(togglePreviewUrl)}
				title={togglePreviewImpossible ? undefined : togglePreviewTitle}
				disabled={togglePreviewImpossible}
				style={{ border: 0, background: 'transparent', position: 'relative', top: '2px' }}
			>
				<Icon name={config.preview ? 'eye' : 'eye-slash'} />
			</button>
			<input
				type="text"
				readOnly
				tabIndex={-1}
				value={config.internalVersionTag}
				size={config.internalVersionTag?.length}
				onClick={handleClick}
				onKeyDown={handleKeyPress}
				title="🚧 Tryck här och sedan på <Backspace> om du vill gömma detta fält"
			/>
		</div>
	);
}
